<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import AdminBar from '@/components/AdminBar.vue';

export default {
  components: {
    AdminBar,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
};
</script>
<template>
  <div>
    <AdminBar :visible="true" :user="user" v-if="user" />
    <router-view />
  </div>
</template>
